<template>

    <div>
        <div class="vx-row" v-if="item">
    <div class="vx-col w-full lg:w-1/1">
      <!-- ABOUT CARD -->
      <vx-card :title="`Edit ${$i18n.t('Sparepart')}`" class="mt-base">
        <!-- ACTION SLOT -->
        <template slot="actions">
            <div class="flex gap-2">
                <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'sparepart-report' })">
                    Kembali
                </vs-button>
                <vs-button color="success"  icon="save" @click="storeItem">
                    Save
                </vs-button>
            </div>
        </template>
        <div class="vx-row my-2 w-full md:w-1/2 px-3">
            <label for="" class="font-bold mb-1">Title</label>
            <vs-input class="w-full" v-model="form.title" placeholder="Please write title" />
        </div>
        <div class="vx-row my-2 w-full md:w-1/2 px-3">
            <label class="font-bold mb-1">{{ $i18n.t('Category') }}</label>
            <v-select class="w-full" v-model="form.category" :clearable="true" :options="categories" placeholder="Choose one category"></v-select>
        </div>
        <div class="vx-row my-2 w-full md:w-1/2 px-3">
        <label class="font-bold mb-1">{{ $i18n.t('Brand') }}</label>
        <div v-if="form.category != null" class="w-full">
            <v-select v-model="form.brand"  :clearable="true" :options="form.category.brands" placeholder="Choose one brand"></v-select>
        </div>
        <div class="w-full" v-else>
            <v-select placeholder="Choose category first" disabled></v-select>
        </div>
        </div>
        <div class="vx-row my-2 w-full md:w-1/2 px-3">
        <label class="font-bold mb-1">{{ $i18n.t('Attribute') }}</label>
          <div class="vx-row my-2 flex justify-evenly items-end" v-for="(attr, index) in form.attribute" :key="index">
            <div class="vx-col md:w-1/1 w-1/4">
              <small class="mb-1">{{ $i18n.t('Key') }}</small>
              <vs-input class="w-full" v-model="attr.key" />
            </div>
            <div class="vx-col md:w-1/1 w-3/5">
              <small class="mb-1">{{ $i18n.t('Value') }}</small>
              <vs-input class="w-full" v-model="attr.value" />
            </div>
            <div class="vx-col md:w-1/1 w-auto">
              <feather-icon icon="Trash2Icon" class="text-danger cursor-pointer h-5 w-5" @click="deleteAttr(index)"></feather-icon>
            </div>
          </div>
          <vs-button color="primary" icon="add" size="small" class="w-full my-4" @click="addAttr">Add Attribute</vs-button>
        </div>
        <div class="vx-row mt-5">
            <div class="vx-col w-full md:w-1/2">
            <h6 class="font-bold mb-1">Sparepart In</h6>
              <div class="vx-row mt-5">
                <div class="vx-col w-full md:w-1/2">
                    <img :src="item.sparepart_in.picture" class="picture w-full">
                    <vs-button color="primary" size="small" type="border" class="w-full my-4" @click="uploadImageIn">Upload Image</vs-button>
                </div>
                <div class="vx-col w-full md:w-1/2">
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Serial Number</label>
                        <vs-input class="w-full" v-model="form.sparepart_in.serial_number" placeholder="Please write title" />
                    </div>
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Date</label>
                        <vs-input class="w-full" type="datetime-local" @change="formatDate" v-model="form.sparepart_in.date" />
                    </div>
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Note</label>
                        <vs-textarea class="w-full" rows="4" v-model="form.sparepart_in.note" />
                    </div>
                </div>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/2" v-if="item.sparepart_out != null">
              <h6 class="font-bold mb-1">Sparepart Out</h6>
              <div class="vx-row mt-5">
                <div class="vx-col w-full md:w-1/2">
                    <img :src="item.sparepart_out.picture" class="picture w-full">
                    <vs-button color="primary" size="small" type="border" class="w-full my-4" @click="uploadImageOut">Upload Image</vs-button>
                </div>
                <div class="vx-col w-full md:w-1/2">
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Serial Number</label>
                        <vs-input class="w-full" v-model="form.sparepart_out.serial_number" placeholder="Please write title" />
                    </div>
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Date</label>
                        <vs-input class="w-full" type="datetime-local" @change="formatDate" v-model="form.sparepart_out.date" />
                    </div>
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Note</label>
                        <vs-textarea class="w-full" rows="4" v-model="form.sparepart_out.note" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <input id="forSparepartIn" type="file" accept="image/jpeg, image/png, image/jpg" v-on:change="handleFileChangeSparepartIn" hidden />
    <input id="forSparepartOut" type="file" accept="image/jpeg, image/png, image/jpg" v-on:change="handleFileChangeSparepartOut" hidden />


      </vx-card>

    </div>
  </div>
    </div>
  
  </template>
  
  <script>
import storage from '@/storage.js'
import moment from 'moment'
import vSelect from 'vue-select'

  
  
  export default {
    name: 'EditSparepartVue',
    computed: {
        encrypt () {
        return this.$secure.encrypt(this.id);
        },

        isDetail(){
        if(this.$route.params.id){
            return true;
        }
        return false;
        }
    },
    data() {
        return {
            id: '',
            uploadMode: '',
            form: {
                title: '',
                attribute: [],
                category: null,
                brand: null,
                sparepart_in: null,
                sparepart_out: null
            },
            categories: [],
            item: []
        }
    },
    methods: {
        deleteAttr(index){
            return this.form.attribute.splice(index, 1)
        },
            addAttr(){
            return this.form.attribute.push({
            key: "",
            value: ""
            })
        },
        handleFileChangeSparepartIn(event) {
            // Assuming only one file is uploaded
            if (event.target.files.length > 0) {
                this.form.picture_in = event.target.files[0];
                this.uploadStore()
            }
        },
        handleFileChangeSparepartOut(event) {
            // Assuming only one file is uploaded
            if (event.target.files.length > 0) {
                this.form.picture_out = event.target.files[0];
                this.uploadStore()
            }
        },
      formatDate(){
        return moment(this.form.date).format('YYYY-MM-DD HH:mm');
      },
      storeItem(){
        let body = {
            title: this.form.title,
            attribute: JSON.stringify(this.form.attribute),
            sparepart_category_id: this.form.category.code.toString(),
            brand_id: this.form.brand.code.toString(),
            date_in: this.form.sparepart_in.date,
            serial_number_in: this.form.sparepart_in.serial_number,
            note_in: this.form.sparepart_in.note,
            date_out: this.form.sparepart_out != null ? this.form.sparepart_out.date : null,
            note_out: this.form.sparepart_out != null ? this.form.sparepart_out.note : null,
            serial_number_out: this.form.sparepart_out != null ? this.form.sparepart_out.serial_number : null,
            pic: this.$store.state.AppActiveUser.employee.name
        }

        let status = 'in'

        if (this.item.sparepart_out != null) {
            status = 'out'
        }

        const payload = {
            id: this.id,
            body: body,
            status: status
        }

        this.$store.dispatch("sparepart/updateSparepart", payload)
        .then(
            resp => {
                this.$router.push({name: 'sparepart-show', params: { id: this.$secure.encrypt(this. id) }});
                this.$vs.notify({
                title:'Success',
                text: resp.data.message,
                color:'success'
                })
            }
        ).catch(
            err => {
                this.$vs.notify({
                title:'Opps something error',
                text: err.data.message,
                color:'danger'
                })
            }
        )

        
        },
        uploadImageIn(){
            this.uploadMode = 'in'
            document.getElementById('forSparepartIn').click();
        },
        uploadImageOut(){
            this.uploadMode = 'out'
            document.getElementById('forSparepartOut').click();
        },
        uploadStore() {

        let payload = {
            status: this.uploadMode,
            id: this.uploadMode == 'in' ? this.item.sparepart_in.id : this.item.sparepart_out.id,
        }

        const formData = new FormData()
        formData.set("pic", this.$store.state.AppActiveUser.employee.name)
        if (this.uploadMode == 'in') {
            formData.set("picture", this.form.picture_in)
            payload.body = formData
        } else if (this.uploadMode == 'out') {
            formData.set("picture", this.form.picture_out)
            payload.body = formData
        }

        this.$store.dispatch("sparepart/uploadImage", payload)
        .then(
            resp => {
                this.$vs.notify({
                title:'Success',
                text: resp.data.message,
                color:'success'
                })
            }
        ).catch(
            err => {
                this.$vs.notify({
                title:'Opps something error',
                text: err.data.message,
                color:'danger'
                })
            }
        )

        this.$store.dispatch("sparepart/showSparepart", this.id)
        .then(
            resp => {
             this.item.sparepart_in.picture = storage.sparepart + resp.data.values.sparepart_in.picture
             this.item.sparepart_out.picture = storage.sparepart + resp.data.values.sparepart_out.picture
            }
        ).catch(
            err => {
                this.$vs.notify({
                title:'Opps something error',
                text: err.data.message,
                color:'danger'
                })
            }
        )
      },
      
        getData(){
            this.$store.dispatch("sparepart/showSparepart", this.id)
            .then((resp) => {
                this.item = resp.data.values
                this.item.sparepart_in.date = moment(this.item.sparepart_in.date).format("YYYY-MM-DD HH:mm")
                this.form = {
                    title: this.item.title,
                    attribute: this.item.attribute,
                    category: {
                        label: this.item.category.category_name,
                        code: this.item.category.id
                    },
                    brand: {
                        label: this.item.brand.brand_name,
                        code: this.item.brand.id
                    },
                    sparepart_in: this.item.sparepart_in,
                }
                this.item.sparepart_in.picture = storage.sparepart + this.item.sparepart_in.picture
                if (this.item.sparepart_out) {
                    this.item.sparepart_out.date = moment(this.item.sparepart_out.date).format("YYYY-MM-DD HH:mm")
                    this.form.sparepart_out = this.item.sparepart_out
                    this.item.sparepart_out.picture = storage.sparepart + this.item.sparepart_out.picture
                }
                
            }).catch(
                err => {
                    this.$vs.notify({
                        title:'Opps something error',
                        text: err.data.message,
                        color:'danger'
                        })
                }
            )
        },
        getCategories(){
        this.$store.dispatch("sparepartCategory/getCategories").then(
          resp => {
            this.categories  = resp.data.values.map((category) => {
              return {
                label: category.category_name,
                code: category.id,
                brands: category.brands.map(brand => {
                  return {
                    label: brand.brand_name,
                    code: brand.id
                  }
                })
              }
            });
          }
        ).catch(
          err => {
            this.$vs.notify({
              title:'Opps something error',
              text: err.data.message,
              color:'danger'
            })
          }
        )
      },
    },
    created () {
      if(this.$route.params.id){
        this.id = this.$secure.decrypt(this.$route.params.id);
      }
      this.getData();
      this.getCategories();
    }
  }
  
  </script>
  
  <style lang="scss">
  #page-user-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  .picture {
  width: 200px;
  height: 250px;
}
  </style>
  